<template>

<div class="analytics">

<div class="tools">
		<div class="filters">
		<i class="vsm--icon fa fa-table" @click="this.loadData({'xls':'1'})" style="margin-right:1em;"> XLS</i>
		<!--download-excel :data="newData" type="xlx" :name="this.params.offer+'_'+this.params.interval" style="cursor:pointer; margin-right:1em;">
			<i class="vsm--icon fa fa-table"></i>
		</download-excel-->

		<div style="margin-right:1em;">
			<o-input v-model="kt_campaign" @change="this.loadData({'kt_campaign':kt_campaign})" placeholder="id компании" variant="primary" />
		</div>

		<div style="margin-right:1em;">
			<AnalyticsOffers @change="this.loadData($event)" />
		</div>
		<div style="margin:0 0 0 auto;">
			<Interval ref="interval" @change="this.loadData($event)" />
		</div>
	</div>
</div>

<o-table ref="table" :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'">
	<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">
		{{ row[column.field] }}
	</o-table-column>
</o-table>


</div>

</template>

<script>
	import Interval from './Interval.vue'
	import AnalyticsOffers from './AnalyticsOffers.vue'
	export default {
		name: 'Analytics',
		components: {
			Interval, AnalyticsOffers
		},
		data() {
			return {
				columns: [
					{ field:'product', label:'Product', sortable:true, searchable:true, },
					{ field:'kt_campaign', label:'Campaign', sortable:true, searchable:true, position:'centered' },
					{ field:'kt_landing', label:'Landing', sortable:true, searchable:true, position:'centered' },
					{ field:'kt_offer', label:'Offer', sortable:true, searchable:true, position:'centered' },
					{ field:'cnt', label:'Clicks', sortable:true, position:'right' },
					{ field:'percent_landing', label:'% video landing open', sortable:true, position:'right' },
					{ field:'avg_vsl_landing', label:'Average time video landing', sortable:true, position:'right' },
					{ field:'percent', label:'% VSL open', sortable:true, position:'right' },
					{ field:'avg_vsl', label:'Average time VSL', sortable:true, position:'right' },
				],
				data: [],
				params: { interval:'today' },
				kt_campaign: '',
			}
		},
		methods: {
			loadData(e) {
			console.log(this.$refs.table.filters['kt_offer'])
				if('kt_campaign' in e) this.params.kt_campaign = e.kt_campaign
				if('kt_offer' in this.$refs.table.filters) this.params.kt_offer = this.$refs.table.filters.kt_offer
				if(e.interval) this.params.interval = e.interval
				if(e.interval == 'custom_date_range') {
					this.params.from = e.from
					this.params.to = e.to
				}
				if(e.offer) {
					let i = e.offer.lastIndexOf(' ')
					this.params.offer = e.offer.substring(0, i)
					this.params.country = e.offer.substring(i+1)
				}
				if(this.params.country == 'Products' && !this.params.kt_campaign) { return }
				this.params.xls = (e.xls) ? 1 : 0
				console.log(this.params)

				this.$root.loadData(this.params, this, (dt) => {
					if(e.xls) {
						window.location.href = dt
					} else {
						this.data = dt
					}
				})
			},
		},
		mounted() {
//			this.loadData(null)
		},
	}
</script>

<style>
</style>
